import "./message.css";
import "./messageResponse.css";

function Message({ message, index = 0, loading = false }) {
  return (
    <div className="message">
      <div className="robot_name">
        {index % 2 == 0 ? "@Healthcare_advisor" : "@Me"}
      </div>
      <div className="message_chat">
        {loading ? (
          <div className="loading">
            <div className="dot" />
            <div className="dot" />
            <div className="dot" />
          </div>
        ) : (
          message
        )}
      </div>
    </div>
  );
}

export default Message;
