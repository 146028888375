import { useEffect, useState, useRef } from "react";
import "./App.css";

import Message from "./Message";

function App() {
  const [input, setInput] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState("");
  const [thread, setThread] = useState("");
  const [messages, setMessages] = useState([
    "Hello! How can I assist you today with neurological disease-related questions? ",
  ]);
  const [isError, setIsError] = useState(false);

  const send_input = async (faq = input) => {
    setMessages((prev) => [...prev, faq]);
    setIsDisabled(true);
    setInput("");
    try {
      const response_api = await fetch("https://assistme.website/post_json", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ context: messages, user_question: faq }),
      });

      const from_api = await response_api.json();
      setMessages((prev) => [...prev, from_api.message]);
    } catch {
      setMessages((prev) => [
        ...prev,
        "Hi! It seems that there is an issue on our side. Please come back in few minutes.",
      ]);
      setIsError(true);
    }

    setIsDisabled(false);
    setTimeout(() => focusRef.current.focus(), 0);
  };

  const messageRef = useRef();
  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [messages]);
  const focusRef = useRef(null);
  const submitting = (e) => {
    e.preventDefault();
    send_input();
  };

  return (
    <div className="container">
      <div className="container_right"></div>
      <div className="container_center">
        <div className="welcome_header_bottom">Healthcare advisor</div>

        <div className="form_container">
          {/* <Message message="Hello! How can I assist you today with neurological disease-related questions? " /> */}

          {messages.map((message, index) => (
            <Message message={message} key={message} index={index} />
          ))}

          {isDisabled && <Message message="" loading />}

          <div ref={messageRef}></div>
        </div>

        <form
          onSubmit={submitting}
          className={
            isDisabled ? "input_place input_place_disabled" : "input_place"
          }
        >
          <input
            className="input_chat"
            ref={focusRef}
            value={isDisabled ? "" : input}
            type="text"
            disabled={isDisabled}
            onChange={(e) => setInput(e.target.value)}
          />
          <button type="submit" className="button_send" disabled={isDisabled}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                fill="#ffffff"
                d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
              />
            </svg>
          </button>
        </form>
      </div>
      <div className="container_right"></div>
    </div>
  );
}

export default App;
